var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"text-center d-flex justify-content-center align-items-center loader"}):_c('div',{staticClass:"d-flex flex-column flex-lg-row"},[_c('b-card',{staticClass:"rounded-lg shadow-lg bg-light",attrs:{"id":"vue-slider"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center"},[_c('svg',{class:("card-title-text " + (_vm.progress_checker == 1
                  ? 'deep-colored-bg'
                  : _vm.progress_checker == 0
                  ? 'light-colored-bg'
                  : '')),staticStyle:{"width":"32px","height":"32px"},attrs:{"viewBox":"0 0 100 100","preserveAspectRatio":"none"}},[_c('defs',[_c('mask',{attrs:{"id":"fillMask","x":"0","y":"0","width":"100","height":"100"}},[_c('image',{attrs:{"xlink:href":require("@/assets/images/icons/application-analysis.svg"),"x":"0","y":"0","width":"100","height":"100"}})])]),_c('rect',{staticStyle:{"stroke":"none","fill":"currentColor"},attrs:{"x":"0","y":"0","width":"100","height":"100","mask":"url(\"#fillMask\")"}})])]),_c('div',{staticClass:"d-flex flex-row justify-content-between align-items-center"},[_c('div',{staticClass:"text-left"},[_c('h3',{class:("card-title-text " + (_vm.progress_checker == 0
                    ? 'deep-colored-bg'
                    : _vm.progress_checker == 0
                    ? 'light-colored-bg'
                    : ''))},[_vm._v(" Application Analysis ")])]),_c('div',{staticClass:"d-flex justify-content-start align-items-start"},[(_vm.progress_checker == 0)?_c('button',{staticClass:"border-0 bg-transparent",attrs:{"disabled":"","step":"1"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/tick_badge.png"),"alt":""}})]):(_vm.progress_checker < 0)?_c('button',{staticClass:"border-0 bg-transparent",attrs:{"disabled":"","step":"1"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/blank.png"),"alt":""}})]):_c('button',{staticClass:"border-0 bg-transparent",attrs:{"disabled":"","step":"1"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/tick_badge.png"),"alt":""}})])])])]),_c('b-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('p',{staticClass:"step-card-text"},[_vm._v(" Total "),_c('b',{staticClass:"count"},[_vm._v(_vm._s(_vm.applicantCount.apply_count))]),_vm._v(" applications received for this job post. ")])])],1)],1),_c('b-card',{staticClass:"mx-1 rounded-lg shadow-lg bg-light",attrs:{"id":"vue-slider"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center"},[_c('svg',{class:("card-title-text " + (_vm.progress_checker == 2
                  ? 'deep-colored-bg'
                  : _vm.progress_checker == 1
                  ? 'light-colored-bg'
                  : '')),staticStyle:{"width":"32px","height":"32px"},attrs:{"viewBox":"0 0 100 100","preserveAspectRatio":"none"}},[_c('defs',[_c('mask',{attrs:{"id":"fillMask-2","x":"0","y":"0","width":"100","height":"100"}},[_c('image',{attrs:{"xlink:href":require("@/assets/images/icons/call-interview.svg"),"x":"0","y":"0","width":"100","height":"100"}})])]),_c('rect',{staticStyle:{"stroke":"none","fill":"currentColor"},attrs:{"x":"0","y":"0","width":"100","height":"100","mask":"url(\"#fillMask-2\")"}})])]),_c('div',{staticClass:"d-flex flex-row justify-content-between align-items-center"},[_c('div',{staticClass:"text-left"},[_c('h3',{class:("card-title-text " + (_vm.progress_checker == 1
                    ? 'deep-colored-bg'
                    : _vm.progress_checker == 1
                    ? 'light-colored-bg'
                    : ''))},[_vm._v(" Call for Interview ")])]),_c('div',{staticClass:"d-flex justify-content-start align-items-start"},[(_vm.progress_checker == 1)?_c('button',{staticClass:"border-0 bg-transparent",attrs:{"disabled":"","step":"1"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/tick_badge.png"),"alt":""}})]):(_vm.progress_checker < 1)?_c('button',{staticClass:"border-0 bg-transparent",attrs:{"disabled":"","step":"1"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/blank.png"),"alt":""}})]):_c('button',{staticClass:"border-0 bg-transparent",attrs:{"disabled":"","step":"1"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/tick_badge.png"),"alt":""}})])])])]),_c('b-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('p',{staticClass:"step-card-text"},[_vm._v(" Total "),_c('b',{staticClass:"count"},[_vm._v(_vm._s(_vm.applicantCount.interviw_count))]),_vm._v(" applicants are called for interview. ")])])],1)],1),_c('b-card',{staticClass:"rounded-lg shadow-lg bg-light",attrs:{"id":"vue-slider"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center"},[_c('svg',{class:("card-title-text " + (_vm.progress_checker == 3
                  ? 'deep-colored-bg'
                  : _vm.progress_checker == 2
                  ? 'light-colored-bg'
                  : '')),staticStyle:{"width":"32px","height":"32px"},attrs:{"viewBox":"0 0 100 100","preserveAspectRatio":"none"}},[_c('defs',[_c('mask',{attrs:{"id":"fillMask-3","x":"0","y":"0","width":"100","height":"100"}},[_c('image',{attrs:{"xlink:href":require("@/assets/images/icons/finel-screening.svg"),"x":"0","y":"0","width":"100","height":"100"}})])]),_c('rect',{staticStyle:{"stroke":"none","fill":"currentColor"},attrs:{"x":"0","y":"0","width":"100","height":"100","mask":"url(\"#fillMask-3\")"}})])]),_c('div',{staticClass:"d-flex flex-row justify-content-between align-items-center"},[_c('div',{staticClass:"text-left"},[_c('h3',{class:("card-title-text " + (_vm.progress_checker == 2
                    ? 'deep-colored-bg'
                    : _vm.progress_checker == 2
                    ? 'light-colored-bg'
                    : ''))},[_vm._v(" Screening Test ")])]),_c('div',{staticClass:"d-flex justify-content-start align-items-start"},[(_vm.progress_checker == 2)?_c('button',{staticClass:"border-0 bg-transparent",attrs:{"disabled":"","step":"1"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/tick_badge.png"),"alt":""}})]):(_vm.progress_checker < 2)?_c('button',{staticClass:"border-0 bg-transparent",attrs:{"disabled":"","step":"1"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/blank.png"),"alt":""}})]):_c('button',{staticClass:"border-0 bg-transparent",attrs:{"disabled":"","step":"1"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/tick_badge.png"),"alt":""}})])])])]),_c('b-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('p',{staticClass:"step-card-text"},[_vm._v(" Total "),_c('b',{staticClass:"count"},[_vm._v(_vm._s(_vm.applicantCount.screening_count))]),_vm._v(" applicants are called for screening test. ")])])],1)],1),_c('b-card',{staticClass:"mx-1 rounded-lg shadow-lg bg-light",attrs:{"id":"vue-slider"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center"},[_c('svg',{class:("card-title-text " + (_vm.progress_checker == 4
                  ? 'deep-colored-bg'
                  : _vm.progress_checker == 3
                  ? 'light-colored-bg'
                  : '')),staticStyle:{"width":"32px","height":"32px"},attrs:{"viewBox":"0 0 100 100","preserveAspectRatio":"none"}},[_c('defs',[_c('mask',{attrs:{"id":"fillMask-4","x":"0","y":"0","width":"100","height":"100"}},[_c('image',{attrs:{"xlink:href":require("@/assets/images/icons/interview-session.svg"),"x":"0","y":"0","width":"100","height":"100"}})])]),_c('rect',{staticStyle:{"stroke":"none","fill":"currentColor"},attrs:{"x":"0","y":"0","width":"100","height":"100","mask":"url(\"#fillMask-4\")"}})])]),_c('div',{staticClass:"d-flex flex-row justify-content-between align-items-center"},[_c('div',{staticClass:"text-left"},[_c('h3',{class:("card-title-text " + (_vm.progress_checker == 3
                    ? 'deep-colored-bg'
                    : _vm.progress_checker == 3
                    ? 'light-colored-bg'
                    : ''))},[_vm._v(" Interview ")])]),_c('div',{staticClass:"d-flex justify-content-start align-items-start"},[(_vm.progress_checker == 3)?_c('button',{staticClass:"border-0 bg-transparent",attrs:{"disabled":"","step":"1"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/tick_badge.png"),"alt":""}})]):(_vm.progress_checker < 3)?_c('button',{staticClass:"border-0 bg-transparent",attrs:{"disabled":"","step":"1"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/blank.png"),"alt":""}})]):_c('button',{staticClass:"border-0 bg-transparent",attrs:{"disabled":"","step":"1"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/tick_badge.png"),"alt":""}})])])])]),_c('b-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('p',{staticClass:"step-card-text"},[_vm._v(" Total "),_c('b',{staticClass:"count"},[_vm._v(_vm._s(_vm.applicantCount.viva_count))]),_vm._v(" applicants are called for interview. ")])])],1)],1),_c('b-card',{staticClass:"rounded-lg shadow-lg bg-light",attrs:{"id":"vue-slider"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center"},[_c('svg',{class:("card-title-text " + (_vm.progress_checker == 6
                  ? 'deep-colored-bg'
                  : _vm.progress_checker == 4
                  ? 'light-colored-bg'
                  : '')),staticStyle:{"width":"32px","height":"32px"},attrs:{"viewBox":"0 0 100 100","preserveAspectRatio":"none"}},[_c('defs',[_c('mask',{attrs:{"id":"fillMask-5","x":"0","y":"0","width":"100","height":"100"}},[_c('image',{attrs:{"xlink:href":require("@/assets/images/icons/recruite.svg"),"x":"0","y":"0","width":"100","height":"100"}})])]),_c('rect',{staticStyle:{"stroke":"none","fill":"currentColor"},attrs:{"x":"0","y":"0","width":"100","height":"100","mask":"url(\"#fillMask-5\")"}})])]),_c('div',{staticClass:"d-flex flex-row justify-content-between align-items-center"},[_c('div',{staticClass:"text-left"},[_c('h3',{class:("card-title-text " + (_vm.progress_checker == 4
                    ? 'deep-colored-bg'
                    : _vm.progress_checker == 4
                    ? 'light-colored-bg'
                    : ''))},[_vm._v(" On Boarding ")])]),_c('div',{staticClass:"d-flex justify-content-start align-items-start"},[(_vm.progress_checker == 4)?_c('button',{staticClass:"border-0 bg-transparent",attrs:{"disabled":"","step":"1"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/tick_badge.png"),"alt":""}})]):(_vm.progress_checker < 4)?_c('button',{staticClass:"border-0 bg-transparent",attrs:{"disabled":"","step":"1"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/blank.png"),"alt":""}})]):_c('button',{staticClass:"border-0 bg-transparent",attrs:{"disabled":"","step":"1"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/tick_badge.png"),"alt":""}})])])])]),_c('b-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('p',{staticClass:"step-card-text"},[_vm._v(" Total "),_c('b',{staticClass:"count"},[_vm._v(_vm._s(_vm.applicantCount.hired_count))]),_vm._v(" applicants are hired and onboarded. ")])])],1)],1)],1),_c('div',{staticClass:"px-5"},[_c('vue-slider',{attrs:{"disabled":"","data":_vm.numberValue,"marks":true,"direction":_vm.direction},on:{"change":function($event){$event.preventDefault();return _vm.updateStatus($event)}},model:{value:(_vm.progress_checker),callback:function ($$v) {_vm.progress_checker=$$v},expression:"progress_checker"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }