<template>
  <div>
    <div
      v-if="loading"
      class="text-center d-flex justify-content-center align-items-center loader"
    ></div>

    <div v-else class="d-flex flex-column flex-lg-row">
      <b-card class="rounded-lg shadow-lg bg-light" id="vue-slider">
        <b-row>
          <b-col cols="12">
            <div class="text-center">
              <svg
                style="width: 32px; height: 32px"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                :class="`card-title-text ${
                  progress_checker == 1
                    ? 'deep-colored-bg'
                    : progress_checker == 0
                    ? 'light-colored-bg'
                    : ''
                }`"
              >
                <defs>
                  <mask id="fillMask" x="0" y="0" width="100" height="100">
                    <image
                      xlink:href="@/assets/images/icons/application-analysis.svg"
                      x="0"
                      y="0"
                      width="100"
                      height="100"
                    />
                  </mask>
                </defs>
                <rect
                  x="0"
                  y="0"
                  width="100"
                  height="100"
                  style="stroke: none; fill: currentColor"
                  mask='url("#fillMask")'
                />
              </svg>
            </div>
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <div class="text-left">
                <h3
                  :class="`card-title-text ${
                    progress_checker == 0
                      ? 'deep-colored-bg'
                      : progress_checker == 0
                      ? 'light-colored-bg'
                      : ''
                  }`"
                >
                  Application Analysis
                </h3>
              </div>
              <div class="d-flex justify-content-start align-items-start">
                <button
                  disabled
                  v-if="progress_checker == 0"
                  step="1"
                  class="border-0 bg-transparent"
                >
                  <img src="@/assets/images/icons/tick_badge.png" alt="" />
                </button>
                <button
                  disabled
                  v-else-if="progress_checker < 0"
                  step="1"
                  class="border-0 bg-transparent"
                >
                  <img src="@/assets/images/icons/blank.png" alt="" />
                </button>
                <button
                  disabled
                  v-else
                  step="1"
                  class="border-0 bg-transparent"
                >
                  <img src="@/assets/images/icons/tick_badge.png" alt="" />
                </button>
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="pt-1">
            <p class="step-card-text">
              Total
              <b class="count">{{ applicantCount.apply_count }}</b> applications
              received for this job post.
            </p>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="mx-1 rounded-lg shadow-lg bg-light" id="vue-slider">
        <b-row>
          <b-col cols="12">
            <div class="text-center">
              <svg
                style="width: 32px; height: 32px"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                :class="`card-title-text ${
                  progress_checker == 2
                    ? 'deep-colored-bg'
                    : progress_checker == 1
                    ? 'light-colored-bg'
                    : ''
                }`"
              >
                <defs>
                  <mask id="fillMask-2" x="0" y="0" width="100" height="100">
                    <image
                      xlink:href="@/assets/images/icons/call-interview.svg"
                      x="0"
                      y="0"
                      width="100"
                      height="100"
                    />
                  </mask>
                </defs>
                <rect
                  x="0"
                  y="0"
                  width="100"
                  height="100"
                  style="stroke: none; fill: currentColor"
                  mask='url("#fillMask-2")'
                />
              </svg>
            </div>
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <div class="text-left">
                <h3
                  :class="`card-title-text ${
                    progress_checker == 1
                      ? 'deep-colored-bg'
                      : progress_checker == 1
                      ? 'light-colored-bg'
                      : ''
                  }`"
                >
                  Call for Interview
                </h3>
              </div>
              <div class="d-flex justify-content-start align-items-start">
                <button
                  disabled
                  v-if="progress_checker == 1"
                  step="1"
                  class="border-0 bg-transparent"
                >
                  <img src="@/assets/images/icons/tick_badge.png" alt="" />
                </button>
                <button
                  disabled
                  v-else-if="progress_checker < 1"
                  step="1"
                  class="border-0 bg-transparent"
                >
                  <img src="@/assets/images/icons/blank.png" alt="" />
                </button>
                <button
                  disabled
                  v-else
                  step="1"
                  class="border-0 bg-transparent"
                >
                  <img src="@/assets/images/icons/tick_badge.png" alt="" />
                </button>
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="pt-1">
            <p class="step-card-text">
              Total
              <b class="count">{{ applicantCount.interviw_count }}</b>
              applicants are called for interview.
            </p>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="rounded-lg shadow-lg bg-light" id="vue-slider">
        <b-row>
          <b-col cols="12">
            <div class="text-center">
              <svg
                style="width: 32px; height: 32px"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                :class="`card-title-text ${
                  progress_checker == 3
                    ? 'deep-colored-bg'
                    : progress_checker == 2
                    ? 'light-colored-bg'
                    : ''
                }`"
              >
                <defs>
                  <mask id="fillMask-3" x="0" y="0" width="100" height="100">
                    <image
                      xlink:href="@/assets/images/icons/finel-screening.svg"
                      x="0"
                      y="0"
                      width="100"
                      height="100"
                    />
                  </mask>
                </defs>
                <rect
                  x="0"
                  y="0"
                  width="100"
                  height="100"
                  style="stroke: none; fill: currentColor"
                  mask='url("#fillMask-3")'
                />
              </svg>
            </div>

            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <div class="text-left">
                <h3
                  :class="`card-title-text ${
                    progress_checker == 2
                      ? 'deep-colored-bg'
                      : progress_checker == 2
                      ? 'light-colored-bg'
                      : ''
                  }`"
                >
                  Screening Test
                </h3>
              </div>
              <div class="d-flex justify-content-start align-items-start">
                <button
                  disabled
                  v-if="progress_checker == 2"
                  step="1"
                  class="border-0 bg-transparent"
                >
                  <img src="@/assets/images/icons/tick_badge.png" alt="" />
                </button>
                <button
                  disabled
                  v-else-if="progress_checker < 2"
                  step="1"
                  class="border-0 bg-transparent"
                >
                  <img src="@/assets/images/icons/blank.png" alt="" />
                </button>
                <button
                  disabled
                  v-else
                  step="1"
                  class="border-0 bg-transparent"
                >
                  <img src="@/assets/images/icons/tick_badge.png" alt="" />
                </button>
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="pt-1">
            <p class="step-card-text">
              Total
              <b class="count">{{ applicantCount.screening_count }}</b>
              applicants are called for screening test.
            </p>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="mx-1 rounded-lg shadow-lg bg-light" id="vue-slider">
        <b-row>
          <b-col cols="12">
            <div class="text-center">
              <svg
                style="width: 32px; height: 32px"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                :class="`card-title-text ${
                  progress_checker == 4
                    ? 'deep-colored-bg'
                    : progress_checker == 3
                    ? 'light-colored-bg'
                    : ''
                }`"
              >
                <defs>
                  <mask id="fillMask-4" x="0" y="0" width="100" height="100">
                    <image
                      xlink:href="@/assets/images/icons/interview-session.svg"
                      x="0"
                      y="0"
                      width="100"
                      height="100"
                    />
                  </mask>
                </defs>
                <rect
                  x="0"
                  y="0"
                  width="100"
                  height="100"
                  style="stroke: none; fill: currentColor"
                  mask='url("#fillMask-4")'
                />
              </svg>
            </div>

            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <div class="text-left">
                <h3
                  :class="`card-title-text ${
                    progress_checker == 3
                      ? 'deep-colored-bg'
                      : progress_checker == 3
                      ? 'light-colored-bg'
                      : ''
                  }`"
                >
                  Interview
                </h3>
              </div>
              <div class="d-flex justify-content-start align-items-start">
                <button
                  disabled
                  v-if="progress_checker == 3"
                  step="1"
                  class="border-0 bg-transparent"
                >
                  <img src="@/assets/images/icons/tick_badge.png" alt="" />
                </button>
                <button
                  disabled
                  v-else-if="progress_checker < 3"
                  step="1"
                  class="border-0 bg-transparent"
                >
                  <img src="@/assets/images/icons/blank.png" alt="" />
                </button>
                <button
                  disabled
                  v-else
                  step="1"
                  class="border-0 bg-transparent"
                >
                  <img src="@/assets/images/icons/tick_badge.png" alt="" />
                </button>
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="pt-1">
            <p class="step-card-text">
              Total
              <b class="count">{{ applicantCount.viva_count }}</b> applicants
              are called for interview.
            </p>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="rounded-lg shadow-lg bg-light" id="vue-slider">
        <b-row>
          <b-col cols="12">
            <div class="text-center">
              <svg
                style="width: 32px; height: 32px"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                :class="`card-title-text ${
                  progress_checker == 6
                    ? 'deep-colored-bg'
                    : progress_checker == 4
                    ? 'light-colored-bg'
                    : ''
                }`"
              >
                <defs>
                  <mask id="fillMask-5" x="0" y="0" width="100" height="100">
                    <image
                      xlink:href="@/assets/images/icons/recruite.svg"
                      x="0"
                      y="0"
                      width="100"
                      height="100"
                    />
                  </mask>
                </defs>
                <rect
                  x="0"
                  y="0"
                  width="100"
                  height="100"
                  style="stroke: none; fill: currentColor"
                  mask='url("#fillMask-5")'
                />
              </svg>
            </div>

            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <div class="text-left">
                <h3
                  :class="`card-title-text ${
                    progress_checker == 4
                      ? 'deep-colored-bg'
                      : progress_checker == 4
                      ? 'light-colored-bg'
                      : ''
                  }`"
                >
                  On Boarding
                </h3>
              </div>
              <div class="d-flex justify-content-start align-items-start">
                <button
                  disabled
                  v-if="progress_checker == 4"
                  step="1"
                  class="border-0 bg-transparent"
                >
                  <img src="@/assets/images/icons/tick_badge.png" alt="" />
                </button>
                <button
                  disabled
                  v-else-if="progress_checker < 4"
                  step="1"
                  class="border-0 bg-transparent"
                >
                  <img src="@/assets/images/icons/blank.png" alt="" />
                </button>
                <button
                  disabled
                  v-else
                  step="1"
                  class="border-0 bg-transparent"
                >
                  <img src="@/assets/images/icons/tick_badge.png" alt="" />
                </button>
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="pt-1">
            <p class="step-card-text">
              Total
              <b class="count">{{ applicantCount.hired_count }}</b> applicants
              are hired and onboarded.
            </p>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <div class="px-5">
      <vue-slider
        disabled
        v-model="progress_checker"
        :data="numberValue"
        :marks="true"
        :direction="direction"
        @change.prevent="updateStatus"
      />
    </div>
  </div>
</template>

<script>
import store from "@/store/index";
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import VueSlider from "vue-slider-component";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BAvatar,
    BButton,
    BFormInput,
    BDropdown,
    BDropdownItem,
    VueSlider,
    BSpinner,
  },
  props: {
    applicantCount: {
      type: Object,
      default: () => ({}),
    },
    progress_checker: Number,
  },
  data() {
    return {
      job: {},
      loading: false,
      numberValue: [0, 1, 2, 3, 4],
      marks: [0, 25, 50, 75, 100],
      dir: "ltr",
    };
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = "rtl";
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = "ltr";
      return this.dir;
    },
  },
};
</script>

<style scoped>
.card-title-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  margin-top: 10px;
  text-align: start;
}

.light-colored-bg {
  color: #7190ef !important;
}

.deep-colored-bg {
  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef 100%);
  color: #264296;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-name-property {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 43px;
  color: #7190ef;
}

.card-name {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 43px;
  /* identical to box height */

  /* Blue Soft */

  /* color: #7190EF; */
}

.btn-secondary {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.step-card-text {
  /* 12 regular */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  /* Text 2 */

  color: #a8adaf;
}

.job-status-card {
  height: 120px !important;
}

@media screen and (max-width: 1440px) and (min-width: 1020px) {
  .job-status-card {
    display: block;
    height: 100%;
  }
}

.count {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* Text Additional */

  color: #264296;
}
</style>
